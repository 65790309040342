import React from "react";

import { Button } from "react-bootstrap";
import { GripVertical } from "react-bootstrap-icons";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";

import withModalTrigger from "components/Modals/ModalTrigger";
import { setActiveRowId } from "state/actions";

import { COMPONENT_FORM_MAPPING } from "./ComponentForms";
import { COMPONENT, STORAGE_KEY_PAGES } from "./types";

const DraggableComponent = ({ id, type, children }) => {
  // eslint-disable-next-line no-empty-pattern
  const [{ cursor }, drag] = useDrag({
    item: { id, type },
    collect: (monitor) => ({
      cursor: monitor.isDragging() ? "grabbing" : "grab",
    }),
  });

  return (
    <div ref={drag} style={{ cursor }}>
      {children}
    </div>
  );
};

const AddRowButton = withModalTrigger((props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    // Reset the modal forms state and launch the add/edit row modal.
    dispatch(setActiveRowId(""));
    props.setActiveModal();
  };

  return (
    <Button variant="primary" block onClick={handleClick}>
      Add Row
    </Button>
  );
});

const SavePageButton = () => {
  const pages = useSelector((state) => state.dynamicPages.pages);

  const handleClick = () => {
    localStorage.setItem(STORAGE_KEY_PAGES, JSON.stringify(pages));
    alert("Page saved successfully.");
  };

  return (
    <Button className="mb-1" variant="success" block onClick={handleClick}>
      Save
    </Button>
  );
};

const DynamicPageActionMenu = () => {
  return (
    <div className="dynamic-page-sidebar">
      <div className="sidebar-heading">
        <h3>Actions</h3>

        <SavePageButton />

        <AddRowButton targetModalId="dynamic-row-form" />
      </div>

      <hr />

      <h3 className="sidebar-heading">Components</h3>

      <div className="list-group list-group-flush">
        {Object.keys(COMPONENT_FORM_MAPPING).map((componentId, index) => {
          const { title } = COMPONENT_FORM_MAPPING[componentId];

          return (
            <DraggableComponent key={index} id={componentId} type={COMPONENT}>
              <div className="list-group-item list-group-item-action">
                {title}
                <GripVertical className="float-right mt-1" size={18} />
              </div>
            </DraggableComponent>
          );
        })}
      </div>
    </div>
  );
};

export default DynamicPageActionMenu;
