// A place to create dynamic pages using the page builder tools.
import React, { useEffect } from "react";

import { Router } from "@reach/router";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import DynamicPage from "components/DynamicPages/DynamicPage";
import { STORAGE_KEY_PAGES } from "components/DynamicPages/types";
import Page from "components/Page/Page";
import { setPagesConfig } from "state/actions";

const defaultPageConfig = [
  {
    name: "Create an Online Community Portal page",
    children: [
      {
        name: "Create a page heading",
        columnProps: { xs: 12, className: "mb-3" },
        moduleComponentName: "SectionHeading",
        moduleProps: {
          heading: {
            priority: 1,
            text: "Create an Online Community Portal page.",
          },
        },
      },
      {
        name: "Add page button",
        columnProps: { xs: 12, className: "d-flex justify-content-end" },
        moduleComponentName: "ButtonLink",
        moduleProps: {
          children: `Add page`,
          targetModalId: "dynamic-page-form",
        },
      },
    ],
    rowProps: {
      noGutters: true,
      className: "py-2 pt-md-5",
    },
    wrapWithContainer: true,
  },
  {
    name: "List of created pages",
    children: [
      { name: "Created pages list", moduleComponentName: "DynamicPageList" },
    ],
    rowProps: {
      noGutters: true,
    },
    wrapWithContainer: true,
  },
];

const DefaultView = () => {
  return (
    <Container fluid className="page-view">
      <Helmet>
        <body className="hide-chatwoot" />
      </Helmet>
      <Page config={defaultPageConfig} />
    </Container>
  );
};

const CreatePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const savedPages = JSON.parse(localStorage.getItem(STORAGE_KEY_PAGES));
    dispatch(setPagesConfig(savedPages));
  }, []);

  const createdPages = useSelector((state) => state.dynamicPages.pages);

  // Render a `DynamicPage` if the URL is `create/some-other-path`, otherwise
  // render the `DefaultView`.
  return (
    <Router basepath="/create">
      {Object.keys(createdPages).map((pageTitle) => {
        const pageKey = `${pageTitle}-router-link`;
        const pagePath = `/${pageTitle}`;

        return <DynamicPage path={pagePath} key={pageKey} />;
      })}
      <DefaultView path="/" />
    </Router>
  );
};

export default CreatePage;
