import React, { useEffect } from "react";

import { Container } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "react-sidebar";

import Page from "components/Page/Page";
import { setActivePageName } from "state/actions";

import DynamicPageActionMenu from "./DynamicPageActionMenu";

const DynamicPage = ({ location }) => {
  const dispatch = useDispatch();

  const pages = useSelector((state) => state.dynamicPages.pages);

  const activePageName = location.pathname.replace("/create/", "");
  const pageConfig = pages[activePageName] || { config: [] };

  useEffect(() => {
    dispatch(setActivePageName(activePageName));
  }, [activePageName, dispatch]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Helmet>
        <body className="hide-chatwoot" />
      </Helmet>
      <Sidebar sidebar={<DynamicPageActionMenu />} docked>
        <Container fluid className="page-view created-page">
          <Page config={pageConfig.config} isEditable />
        </Container>
      </Sidebar>
    </DndProvider>
  );
};

export default DynamicPage;
